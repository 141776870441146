<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <div>

        <!-- Media -->
        <b-media class="mb-0">
          <template #aside>
            <b-overlay
              :show="showUploadLoader"
              rounded="sm"
            >

              <b-avatar
                ref="previewEl"
                :src="userData.avatarURL"
                :text="avatarText(userData.fullname)"
                :variant="`light-${resolveUserRoleVariant(userData.role)}`"
                size="90px"
                rounded
              />
            </b-overlay>
          </template>
          <h4 class="mb-1">
            {{ userData.fullname }}
          </h4>
          <div class="d-flex flex-wrap">
            <b-button
              variant="primary"
              @click="$refs.refInputEl.click()"
            >
              <input
                ref="refInputEl"
                type="file"
                class="d-none"
                @input="uploadImage"
              >
              <span class="d-none d-sm-inline">Uploaden</span>
              <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
            <b-button
              variant="outline-secondary"
              class="ml-1"
              @click="confirmPasswordResetText(userData.id)"
            >
              <span class="d-none d-sm-inline">Wachtwoord resetten</span>
              <feather-icon
                icon="LockIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
          </div>
          <b-card-text class="text-muted">
            Toegestaan JPG, GIF of PNG. Maximale grootte van 2MB.
          </b-card-text>
        </b-media>
        <validation-observer
            ref="refFormUserUpdateObserver"
        >
          <b-form @submit.prevent="updateUsers(userData)">
            <b-row class="mb-1 mt-0">
              <!-- Field: Full Name -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                    #default="validationContext"
                    name="firstname"
                    rules="required"
                >
                  <b-form-group
                    label="Voornaam"
                    label-for="firstname"
                  >
                    <b-form-input
                      id="firstName"
                      v-model="userData.firstname"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: MiddleName -->
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Tussenvoegsel"
                  label-for="middlename"
                >
                  <b-form-input
                    id="middlename"
                    v-model="userData.middlename"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Lastname -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                    #default="validationContext"
                    name="lastname"
                    rules="required"
                >
                  <b-form-group
                    label="Achternaam"
                    label-for="lastname"
                  >
                    <b-form-input
                      id="lastname"
                      v-model="userData.lastname"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Email -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                    #default="validationContext"
                    name="email"
                    rules="required|email"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="userData.email"
                      type="email"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Company -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Bedrijf"
                  label-for="company"
                >
                  <v-select
                    v-model="userData.companyID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="organisationOptions"
                    value="id"
                    label="name"
                    :reduce="organisation => organisation.id"
                    :clearable="false"
                    input-id="organisations"
                    @change="getLocations(userData.companyID, true)"
                    @input="getLocations(userData.companyID, true)"
                  />
                </b-form-group>
              </b-col>

              <b-col
              cols="12"
              md="4"
              v-if="locationsOptions.length > 0"
              >
                <!-- locations -->
                <validation-provider
                    #default="validationContext"
                    name="location"
                    rules="required"
                >
                  <b-form-group
                      label="Locatie"
                      label-for="location"
                      :state="getValidationState(validationContext)"
                  >
                    <v-select
                        v-model="userData.locationID"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="locationsOptions"
                        value="id"
                        label="name"
                        :reduce="location => location.id"
                        :clearable="false"
                        input-id="location"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Status -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Status"
                  label-for="user-status"
                >
                  <v-select
                    v-if="userData.status != 8"
                    v-model="userData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="user-status"
                  />

                  <b-form-input
                    v-if="userData.status == 8"
                    id="status"
                    :value="resolveUserStatusName(userData.status)"
                    type="text"
                    readonly="readonly"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Role -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="User Role"
                  label-for="user-role"
                >
                  <v-select
                    v-model="userData.role"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="roleOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="user-role"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Created date -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Aanmaakdatum"
                  label-for="createTimestamp"
                >
                  <b-form-input
                    id="createTimestamp"
                    v-model="userData.createTimestamp"
                    type="text"
                    readonly="readonly"
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <!-- Action Buttons -->
            <b-button
              v-if="$can('update', 'user')"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              <b-spinner
                  small
                  class="mr-1"
                  v-if="showUpdateUserSpinner"
              />
              Opslaan
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BOverlay,
  BFormInvalidFeedback,
  BSpinner, BCardText,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import useUsersList from '@/views/pages/user/useUsersList'
import { generatePassword, formatDateTime } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  ValidationObserver, ValidationProvider,
} from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BSpinner,
    BCardText,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      organisationOptions: [],
      showUploadLoader: false,
      showUpdateUserSpinner: false,
      locationsOptions: [],
    }
  },
  mounted() {
    store.dispatch('apps-users-edit/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        response.data.createTimestamp = formatDateTime(response.data.createTimestamp)
        this.userData = response.data
        this.getLocations(this.userData.companyID, true)
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ophalen gebruiker',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorDefaultMessage.fetchDataError}`,
          },
        })
      })

    store
      .dispatch('apps-users-edit/fetchOrganisations', {
        perPage: 100,
        sortBy: 'name',
        sortDesc: true,
      })
      .then(response => {
        this.organisationOptions = response.data.items
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ophalen organisaties',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorDefaultMessage.fetchDataError}`,
          },
        })
      })
  },
  methods: {
    getLocations(id, hasLocations) {
      if (id && hasLocations) {
        store
          .dispatch('apps-users-edit/fetchLocationsForCompany', id)
          .then(response => {
            this.locationsOptions = response.data.items
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ophalen locaties',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `${errorDefaultMessage.fetchDataError}`,
              },
            })
          })
      }
    },
    confirmPasswordResetText(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, reset het wachtwoord!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.passwordReset(id)
        }
      })
    },
    passwordReset(userId) {
      const password = generatePassword()
      const passwordResetData = {
        id: userId,
        newPassword: password,
      }

      store.dispatch('apps-users-edit/patchUser', passwordResetData)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Wachtwoord Reset!',
            text: `De wachtwoord reset is met succes uitgevoerd. De nieuwe wachtwoord voor de gebruiker is: ${password}`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Wachtwoord Reset!',
            text: `${errorDefaultMessage.resetPasswordError}`,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    async updateUsers(userData) {
      this.showUpdateUserSpinner = true
      await this.$refs.refFormUserUpdateObserver.validate()
        .then(valid => {
          if (valid) {
            store.dispatch('apps-users-edit/updateUser', userData)
              .then(() => {
                this.showUpdateUserSpinner = false
                router.push({ name: 'apps-users-list' })
              })
              .catch(() => {
                this.showUpdateUserSpinner = false
                this.$swal({
                  icon: 'error',
                  title: 'Update gebruiker!',
                  text: `${errorDefaultMessage.updateDataError}`,
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              })
          }
        })
        .catch(() => {
          this.showUpdateUserSpinner = false
        })
    },
    uploadImage() {
      this.showUploadLoader = true
      const file = document.querySelector('input[type=file]').files[0]
      if (file.size > 10485760) {
        this.showUploadLoader = false
        this.$swal({
          icon: 'error',
          title: 'Afbeeldingsgrootte',
          text: 'De maximale afbeeldinggrootte is 10mb. Het huidige bestand is te groot.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      } else {
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            const fileUploadData = {
              file: reader.result,
              relationModel: 'User',
              name: file.name,
              relationID: this.userData.id,
            }

            store.dispatch('apps-users-edit/uploadImage', fileUploadData)
              .then(response => {
                store.dispatch('apps-users-edit/patchUser', { id: this.userData.id, avatar: response.data.fileID })
                  .then(res => {
                    this.userData = res.data
                    this.showUploadLoader = false
                    this.$swal({
                      icon: 'success',
                      title: 'Upload',
                      text: 'Upload van de foto is gelukt!',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch(() => {
                    this.userData = undefined
                    this.showUploadLoader = false
                    this.$swal({
                      icon: 'error',
                      title: 'Uploaden',
                      text: `${errorDefaultMessage.uploadDataError}`,
                      customClass: {
                        confirmButton: 'btn btn-danger',
                      },
                    })
                  })
              })
              .catch(() => {
                this.showUploadLoader = false
                this.$swal({
                  icon: 'error',
                  title: 'Uploaden',
                  text: `${errorDefaultMessage.uploadDataError}`,
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              })
          },
          false,
        )

        reader.readAsDataURL(file)
      }
    },
  },
  setup() {
    const userData = ref(null)
    const refFormUserUpdateObserver = ref(null)
    const USER_APP_STORE_MODULE_NAME = 'apps-users-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      getValidationState,
    } = formValidation()

    const {
      resolveUserRoleVariant,
      resolveUserStatusName,
      roleOptions,
      statusOptions,
    } = useUsersList()

    return {
      resolveUserRoleVariant,
      resolveUserStatusName,
      avatarText,
      getValidationState,
      refFormUserUpdateObserver,
      roleOptions,
      statusOptions,
      userData,
      required,
      formatDateTime,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
